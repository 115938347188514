.clear {
    clear: both;
}

.wrapper-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 19;
    padding: 0;
    width: auto;
    align-self: flex-end;
}

.wrapper-widget-z {
    z-index: 19;
}

.wrapper-widget.active {
    z-index: 30;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.wrapper-widget > div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}

.wrapper-widget.active .wrapper-widget-content {
    width: 360px;
    background-color: transparent;
    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    -moz-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    margin-right: 20px;
}

.wrapper-widget.active .wrapper-widget-content .btn svg {
    color: #00af69 !important;
}

.wrapper-widget.active .wrapper-widget-content .btn.collapsed svg {
    color: rgb(87, 88, 89) !important;
}

.wrapper-widget.active .wrapper-widget-content > div.collapse.show {
    background-color: #fff;
}

.wrapper-widget .wrapper-widget-content a.wrapper-widget-image {
    display: block;
    margin: 0 auto;
}

.wrapper-widget .wrapper-widget-content .wrapper-widget-image-container {
    width: 100%;
    /*background-color: #0db573;*/
    /*background: -webkit-gradient(linear,left top,left bottom,from(#06a55d),to(#16435e));*/
    /*background: -o-linear-gradient(top,#06a55d,#16435e);*/
    /*background: linear-gradient(to bottom,#06a55d,#16435e);*/
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-border-top-left-radius: 10px;
    -moz-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
}

.wrapper-widget.active .wrapper-widget-content .wrapper-widget-image-container {
    background-color: #fff;
    overflow: hidden;
}

.wrapper-widget .wrapper-widget-content .wrapper-widget-image-container h4 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    margin: 0;
    background: -webkit-gradient(linear,left top,left bottom,from(#06a55d),to(#16435e));
    background: -o-linear-gradient(top,#06a55d,#16435e);
    background: linear-gradient(to bottom,#06a55d,#16435e);
    display: none;
}

.wrapper-widget.active .wrapper-widget-content .wrapper-widget-image-container h4 {
    display: block;
}

.wrapper-widget .wrapper-widget-content .wrapper-widget-image-container .widget-image-content {
    max-width: 150px;
    display: block;
    margin: 0 20px 0 auto;
}

.wrapper-widget.active .wrapper-widget-content .wrapper-widget-image-container .widget-image-content {
    margin: 30px auto 20px auto;
}

/* Not Opened Collapse Style */
.wrapper-widget .wrapper-widget-content .accordion > .card {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    overflow: inherit;
    background: #fff;
    border-bottom: 1px #ddd solid;
}

/* Opened Collapse Style */
.wrapper-widget .wrapper-widget-content > .collapse > .accordion > .card {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    overflow: inherit;
    background: #fff;
    border-bottom: 1px #ddd solid;
    margin: 0 !important;
}

.wrapper-widget .wrapper-widget-content > .collapse > .accordion > .card:nth-child(2) {
    border-bottom: 1px #ddd solid !important;
}

.wrapper-widget .wrapper-widget-content > .collapse > .accordion > .card:last-child {
    border-bottom: none !important;
}

.wrapper-widget .wrapper-widget-content .accordion > .card:not(:first-of-type),
.wrapper-widget .wrapper-widget-content .accordion > .card:not(:last-of-type) {
    border-bottom: 1px #ddd solid !important;
}

.wrapper-widget .wrapper-widget-content .accordion > .card .card-header {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #fff;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
}

.wrapper-widget .wrapper-widget-content .accordion > .card .card-header h2 {
    margin: 0;
    padding: 0;
}

.wrapper-widget .wrapper-widget-content .accordion > .card .card-header h2 button.btn.collapsed {
    color: #575859;
}

.wrapper-widget .wrapper-widget-content .accordion > .card .card-header h2 button.btn {
    color: #00af69;
    font-size: 20px;
    padding: 20px !important;
    margin: 0 !important;
    height: auto !important;
}

.wrapper-widget .btn:focus, .wrapper-widget .btn.focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/* Widget Content */
.wrapper-widget .wrapper-widget-content .accordion .card-body {
    padding: 20px !important
}

.wrapper-widget .wrapper-widget-content .accordion .card-body h3 {
    font-size: 20px;
    margin: 0 0 10px 0;
    font-weight: 700;
}

.wrapper-widget .wrapper-widget-content .accordion .card-body p {
    font-size: 16px;
    margin: 0 0 10px 0;
}

.wrapper-widget .wrapper-widget-content .accordion .card-body a {
    font-size: 16px;
}

.wrapper-widget .wrapper-widget-content .accordion > .card .card-header h2 button svg {
    margin-bottom: 3px;
    margin-right: 10px;
}

.wrapper-widget .widget-new-item {
    top: 10px;
    left: 70px;
    position: absolute;
    background: #ffc827;
    padding: 6px 14px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    z-index: 30;
}

.wrapper-widget.active .widget-new-item {
    top: -18px;
    left: 20px;
}

.btn .plus-or-minus {
    display: none;
}

.btn .plus-or-minus:last-child {
    display: inline-block;
}

.btn.collapsed .plus-or-minus {
    display: inline-block;
}

.btn.collapsed .plus-or-minus:last-child {
    display: none;
}

.widget-new-content {
    background-color: transparent;
    color: #fff;
    display: inline-block;
    padding: 2px 20px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    position: absolute;
    top: -50px;
    right: -62px;
    transform: rotate(88deg);
}

.widget-new-content svg {
    height: 200px;
}

.widget-new-content path {
    fill: transparent;
}

.widget-new-content text {
    fill: orange;
    font-size: 40px;
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .wrapper-widget.active {
        padding: 0 15px;
    }

    .wrapper-widget.active .wrapper-widget-content {
        max-width: 360px;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .widget-new-content {
        font-size: 12px;
        padding: 3px 12px;
    }
}

@media screen and (max-width: 575px) {
    .wrapper-widget {
        right: 10px;
        bottom: 10px;
    }

    .wrapper-widget .wrapper-widget-content .accordion > .card .card-header {
        padding: 4px 0;
    }

    .wrapper-widget .wrapper-widget-content .accordion .card-body h3 {
        font-size: 14px;
        margin: 0 0 6px 0;
    }

    .wrapper-widget .wrapper-widget-content .accordion .card-body p {
        font-size: 14px;
        margin: 0 0 6px 0;
    }

    .wrapper-widget .wrapper-widget-content .accordion .card-body a {
        font-size: 14px;
    }

    .wrapper-widget .wrapper-widget-image img {
        max-width: 200px;
        width: 100%;
    }

    /* Font Sizes */
    .wrapper-widget .wrapper-widget-content .wrapper-widget-image-container h4 {
        font-size: 14px;
    }

    .wrapper-widget .wrapper-widget-content .accordion > .card .card-header h2 button.btn {
        font-size: 14px;
    }

    .wrapper-widget .wrapper-widget-content .wrapper-widget-image-container .widget-image-content {
        max-width: 100px;
    }

    .wrapper-widget .widget-new-item {
        left: 50px;
    }
}

