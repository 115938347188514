.product {
    overflow: hidden;
    margin: 0 4px;
}

.product .polygon {
    position: relative;
    width: 280px;
    height: 280px;
    font-weight: 600;
    margin: 0 -12px;
    overflow: hidden;
}

.twice.two {
    margin: -64px 0;
}

.product .polygon .inner {
    position: relative;
    overflow: hidden;
    background: transparent;
    width: 100%;
    height: 100%;
    transform: rotate(-30deg) skewX(30deg) scaleY(.866);
    border-radius: 15px;
}

.product .polygon .inner:before {
    position: absolute;
    right: 6%;
    bottom: 0;
    left: 6%;
    top: 0;
    transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
    background: #fff;
    content: '';
    border-radius: 45px;
}

.product .polygon.success .inner:before {
    position: absolute;
    right: 3%;
    bottom: 0;
    left: 3%;
    top: 0;
    transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
    background: #fff;
    content: '';
    border-radius: 45px;
    border: 4px solid #00b185;
}

.product .polygon.danger .inner:before {
    position: absolute;
    right: 3%;
    bottom: 0;
    left: 3%;
    top: 0;
    transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
    background: #fff;
    content: '';
    border-radius: 45px;
    border: 4px solid #00b185;
}

.product .polygon.danger .inner:before {
    position: absolute;
    right: 3%;
    bottom: 0;
    left: 3%;
    top: 0;
    transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
    background: #fff;
    content: '';
    border-radius: 45px;
    border: 4px solid #ED1D24;
}

.product .polygon .label {
    color: #64748b;
    font-size: 18px;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    display: inline;
}

.product .polygon .inner {
    position: relative;
    overflow: hidden;
    background: transparent;
    width: 100%;
    height: 100%;
    transform: rotate(-30deg) skewX(30deg) scaleY(.866);
    border-radius: 15px;
}

.product .polygon.success .inner {
    position: relative;
    overflow: hidden;
    background: transparent;
    width: 100%;
    height: 100%;
    transform: rotate(-30deg) skewX(30deg) scaleY(.866);
    border-radius: 15px;
    border: 4px solid #00b185;
}

.product .polygon.danger .inner {
    position: relative;
    overflow: hidden;
    background: transparent;
    width: 100%;
    height: 100%;
    transform: rotate(-30deg) skewX(30deg) scaleY(.866);
    border-radius: 15px;
    border: 4px solid #ED1D24;
}

.countdown {
    width: 180px;
    height: 180px;
    background: rgba(0, 0, 0, .16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin: auto;

    > div {
        &:before {
            content: '';
            display: none;
        }
    }
}

input[type="radio"]+label span {
    transition: background .2s,
    transform .2s;
    min-width: 24px;
    min-height: 24px;
}

input[type="radio"]+label span:hover,
input[type="radio"]+label:hover span {
    transform: scale(1.1);
}

input[type="radio"]:checked+label span {
    background-color: #00b185;
    box-shadow: 0px 0px 0px 2px white inset;
    border: 1px solid #00b185;
}


@media screen and (max-width: 1280px) {
    .single {
        margin-bottom: -64px !important;
    }
}

@media screen and (max-width: 1024px) {

    .twice {
        margin: 0 !important;
    }

    .product {
        margin: 0 4px !important;
    }

    .single {
        margin: 0 !important;
    }
}


@media screen and (max-width: 768px) {
    .single {
        margin: 0 !important;
    }
}

@media screen and (min-width: 610px) and (max-width: 768px) {
    .twice.two {
        margin: -64px 4px 0 4px !important;
    }

    .single {
        margin: -64px !important;
    }
}

#soru .wrapper-games-content {
    background-color: #f8fafc;
}

#soru .games-question-choose-find .soru-box {
    box-shadow: none !important;
    background: url('../../../assets/images/new-design/game/polygon.svg') no-repeat;
    width: 268px !important;
    height: 301px !important;
    margin: 0 auto;
}

#soru .row.top.sec-bul .col-md-12 {
    display: flex;
    flex-direction: column;
}


/* Custom Pick Find Styles */
.wrapper-pick-find {
    position: relative;
    margin-top: 50px;

    @media screen and (min-width: 1024px) {
        padding-top: 200px;
    }

    .pick-find {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 268px;
        height: 301px;
        z-index: 10;
        user-select: none;

        @media screen and (min-width: 1024px) {
            &:hover {
                z-index: 11;
            }

            &:first-child {
                position: absolute;
                top: -50px;
                left: 50%;
                margin-left: -134px;
            }
        }

        .pick-find-polygon-success, .pick-find-polygon-error, .pick-find-polygon-active {
            display: none;
        }

        // Default Polygon
        .pick-find-polygon {
            position: absolute;
            top: 0;
            left: 0;
            min-width: 268px;
            height: 301px;
            transform: scale(1);
        }

        &-active {
            .pick-find-polygon-active {
                display: flex;
                fill: #64748b;
                position: absolute;
                top: 0;
                left: 0;
                min-width: 268px;
                height: 301px;
                transform: scale(1.04);
        
                path {
                    fill: #64748b;
                }
            }
        }

        // Success Polygon
        &-success {
            .pick-find-polygon-success {
                display: flex;
                fill: #00b185;
                position: absolute;
                top: 0;
                left: 0;
                min-width: 268px;
                height: 301px;
                transform: scale(1.04);
        
                path {
                    fill: #00b185;
                }
            }

            .pick-find-polygon {
                top: 50%;
                left: 50%;
                margin-left: -134px;
                margin-top: -151px;
            }
        }

        // Error Polygon
        &-error {
            .pick-find-polygon-error {
                display: flex;
                fill: #ED1D24;
                position: absolute;
                top: 0;
                left: 0;
                min-width: 268px;
                height: 301px;
                transform: scale(1.04);
        
                path {
                    fill: #ED1D24;
                }
            }
        }

        .pick-find-content {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }

    &.wrapper-pick-find-bottom {
        position: relative;
        padding-top: 0;
        margin-top: 1rem;

        @media screen and (min-width: 1024px) {
            margin-top: -50px;
        }

        .pick-find {
            @media screen and (min-width: 1024px) {
                &:first-child {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}

