.link-tab-container .tab-menu-content .tab-content {
	background: white;
	padding: 0 30px;
}

.link-tab-container {
	align-items: baseline !important;
	padding-top:65px;
}

.pp-text {
	color: #64748b;

	h2 {
		color: #fff;
	}
}