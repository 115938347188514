.illumination-text {
    ul, li {
        list-style: disc;
    }

    table {
        table-layout: fixed;

        td {
            border: 1px #111 solid;
            box-sizing: border-box;

            ul {
                padding: 0 20px;
            }

            ul ~ p {
                padding: 0 20px;
            }
        }
    }
}