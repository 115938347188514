.react-responsive-modal-container {
    .react-responsive-modal-modal {
        overflow: hidden;
    }

    .react-responsive-modal-closeButton {
        svg {
            fill: #64748b;
        }
    }
}