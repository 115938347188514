.iys-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow-y: auto;
    color: #fff;
    opacity: 1;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;

    .iys {
        max-width: 900px;
        margin: 25px;
        background-color: #000;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        padding: 16px;

        .iys-list {
            li {
                list-style-type: circle;
            }
        }

        .iys-button {
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}