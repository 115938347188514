.products-list .product-list-content .product-list-detail.active {
    height: 100%;
}

.winston-degrade {
    background: rgb(32,63,144);
    background: linear-gradient(90deg, rgba(32,63,144,1) 0%, rgba(19,41,107,1) 100%);
}

.camel-degrade {
    background: rgb(255,235,1);
    background: linear-gradient(90deg, rgba(255,235,1,1) 0%, rgba(246,206,89,1) 100%);
}

.monte-carlo-degrade {
    background: rgb(231,33,42);
    background: linear-gradient(90deg, rgba(231,33,42,1) 0%, rgba(167,28,36,1) 100%);
}

.ld-degrade {
    background: rgb(229,32,41);
    background: linear-gradient(90deg, rgba(229,32,41,1) 0%, rgba(110,22,24,1) 100%);
}