#bizim-meydan .answers .answer .date {
    padding: 0 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#bizim-meydan .stat-box .heart {
    min-width: 27px;
}

#bizim-meydan .stat-box .icon {
    min-width: 27px;
}

.stats.ml-auto .d-flex {
    padding: 0 10px;
}

.stats.d-flex .comments {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
}

#bizim-meydan .stat-box .stat {
    padding: 0 !important;
}