@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'Winston';
  src: url('pages/modules/competition/assets/fonts/Winston-Medium.woff2') format('woff2'),
      url('pages/modules/competition/assets/fonts/Winston-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Old Stylesheet */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex {
  display: flex;
}

.center-center {
  justify-content: center;
  align-items: center;
}

.p-10 {
  padding: 10px;
}

.m-10 {
  margin: 10px;
}

a:hover {
  text-decoration: none !important;
}

.react-responsive-modal-modal {
  padding: 0 !important;
}
/* End Old Stylesheet */

/* Custom CSS - New Design */
html, body {
  min-height: 100%;
  height: 100%;
}

body {
	// font-family: 'Lexend', sans-serif !important;
  // font-family: 'Roboto Condensed', sans-serif !important;
  // font-family: 'Inter', sans-serif !important;
  font-family: 'Roboto', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #f8fafc;
  color: #64748b;
  height: auto;
}

#accordionWidget .card {
  visibility: visible;
}

#accordionWidget .card .card-header {
  visibility: visible;
}

#accordionWidget .card .collapse {
  visibility: visible;
}

.input:focus {
  outline: 0 !important;
}

.page-head {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
  background-blend-mode: overlay, normal;
  background-image: radial-gradient(circle at 49% 72%, #fff, rgba(255, 255, 255, 0) 23%), linear-gradient(to bottom, #16435e, #16435e);
  color: #fff;

  h3 {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.16);
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding: 48px 0;
  }
}

.detail-page-left-menu {
  background-color: #e2e8f0;
  padding: 24px 81px 31px 24px;

  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;

  -webkit-border-bottom-left-radius: 40px;
  -moz-border-bottom-left-radius: 40px;
  -o-border-bottom-left-radius: 40px;
  border-bottom-left-radius: 40px;

  ul {
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1.5rem;

      a {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        color: #64748b;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.active, &:hover {
        a {
          color: #00b185;
        }
      }
    }
  }
}

// Global Accordion
.global-accordion {
  box-shadow: 0 8px 16px 0 #e2e8f0;

  .border {
    &.active {
      button {
        background-color: #00b185 !important;
        color: #fff !important;
        padding: 25px 32px !important;
        text-transform: none !important;
      }
    }

    button {
      background: transparent;
    }
  }
}

// Login
.dexwuP > span, .kjicut > span {
  text-align: left;
  margin-left: 6px;
}

svg.sc-eqUAAy.bSdOrC.checklist-icon {
  width: 14px;
}

// Rozet Macerası
.lock-grayscale::after {
  content: '';
  width: 34px;
  height: 34px;
  background-image: url('assets/images/new-design/game/lock.png');
  position: absolute;
  top: 0;
  right: 0;
}

.lock-grayscale img {
  filter: grayscale(100%);
}

.rosette-shadow {
  background-image: radial-gradient(circle at 50% 50%, #3254a5 0%, rgba(50, 84, 165, 0) 71%);
  padding: 40px 54px;
}

// FAQ Pages
.basis-full.pl-10 {
  h3 {
    font-weight: bold;
  }
}

.break-word {
  word-break: break-word;
}

.item-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Modal */
div[role="dialog"][aria-modal="true"].fade.modal.show {
  /* CSS kurallarını buraya yazın */
  opacity: 1;
  z-index: 9999;
}

/* Order Modal Button */
.order-modal .modal-footer button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #00B185;
  border-radius: 9999px;
  padding: 0.75rem;
  color: #FFFFFF;
  border: none;
  height: 56px;
}

.warning-modal-section > img {
  margin: 0 auto;
}

ul.pagination {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 991px) {
    margin-top: 2rem;
  }
}

// New-Design About Pages
.about-page .page-container .about-text {
  max-width: 100%;

  @media screen and (max-width: 767px) {
    padding: 0 !important;
  }
}

.about-3-page .page-container {
  @media screen and (max-width: 767px) {
    padding: 40px 0 0 0 !important;
  }
}

.about-page .page-container h2 {
  margin-bottom: 20px;
}

.about-page .page-container h2, .about-active-tab, #bizim-meydan .intro h2 {
  color: #00B185 !important;
}

.basis-full.pl-10 {
  @media screen and (max-width: 767px) {
    padding-left: 0 !important;
  }
}

.arrowButton.green {
  font-weight: 700;
  color: #ffffff;
  border: 2px solid #00B185;
  border-radius: 100px;
  padding: 8px 45px 8px 20px;
  position: relative;
  background-color: #00B185;
}

.menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  text-transform: none !important;
}

.left-side-blank {
  padding-right: 2rem !important;

  @media screen and (max-width: 767px) {
      padding-right: 0 !important;
  }
}

.wrapper-widget-content .collapse {
  border-radius: 0 !important;
}

.alert-success {
  background-color: #00B185;
  border-color: #00B185;
}

// Dropdown Menü Color
.dropdown-item:hover, .dropdown-item:focus {
  color: #0056b3 !important;
}

.glaire {
  background-blend-mode: overlay, normal;
  background-image: radial-gradient(circle at 49% 72%, #fff, rgba(255, 255, 255, 0) 41%), linear-gradient(to bottom, #16435e, #16435e);


  @media screen and (max-width: 767px) {
    margin-bottom: 20px !important;
  }
}

.glaire2 {
  background-blend-mode: overlay, normal;
  background-image: radial-gradient(circle at 50% 14%, #fff, rgba(255, 255, 255, 0) 78%), linear-gradient(to bottom, #004f96, #004f96);
}

// Camel Page
.camel-page .swiper-button-prev:after, .swiper-button-prev.swiper-commercial-brochure-popup-prev:after, 
.camel-page .swiper-button-next:after, .swiper-button-next.swiper-commercial-brochure-popup-next:after {
  content: '';
}

.swiper-button-prev, .swiper-button-next {
  top: 50%;
  margin-top: 30px;
}

// Topic Box
#bizim-meydan .topic-box {
  &:first-child {
    margin-top: 0;
  }
}

#bizim-meydan .topic .down-icon {
  min-width: 50px !important;
}

#bizim-meydan .topic .question > p {
  font-size: 16px !important;
}

#webpack-dev-server-client-overlay {
  display: none;
}

.stats {
  min-width: 280px;
  height: 80px;
}

// Homepage Popup
.react-responsive-modal-modal {
  // background-color: transparent !important;

  @media screen and (max-width: 767px) {
    margin: 20px 0 0 0 !important;
  }

  .popup-firstname-lastname {
    background-color: transparent;
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #01af69;
    font-size: 36px;
    font-weight: 600;

    @media screen and (max-width: 767px) {
      font-size: 22px;
      top: 34px;
      padding: 0 30px;
    }

    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  header {
    > div.mx-auto.flex.items-center {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .topic-box {
    .topic-box-content {
      .answers.collapse {
        .answer.d-flex {
          flex-direction: column;
          align-items: center;
          justify-content: center;  

          .message {
            width: 100%;
            margin: 0 !important;

            .from {
              margin-bottom: 2rem;
            }
          }

          .stats.ml-auto {
            min-width: none;
            width: 100%;

            .d-flex {
              padding: 0;
              justify-content: center;
            }
          }
        }
      }
    }
  } 

  #bizim-meydan .container {
    padding: 50px 15px;
  }
}

// Video Player Component
.videoPlayerModalOverlay {

}

.videoPlayerModal {
  position: relative;
  max-width: 900px !important;
  width: 100% !important;

  .react-responsive-modal-closeButton {
    svg {
      fill: white;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}