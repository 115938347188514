.App {
  font-family: sans-serif;
  text-align: center;
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  margin: auto;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.first-form form .form-info-pass {
  margin-left: 35px;
}

.first-form form .form-info-pass a {
  color: #0d455e;
  font-size: 9px;
  font-weight: 600;
  line-height: 40px;
  display: block;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.wrapper-countdown-circle > div {
  margin: 0 auto 50px auto;
}

#login-form-area .login-btn {
  outline: 0 !important;
}

#login-form-area .login-btn:focus {
  background: #008751;
  border-color: #008751;
}

.wrapper-warning-message {
  color: #ff9800;
  max-width: 400px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .wrapper-warning-message {
    max-width: 280px;
    font-size: 13px;
  }
}

.sc-bcXHqe.dyabIq li {
  font-size: 14px;
  text-align: left;
  line-height: 21px;
  justify-content: center;
  align-items: center;
}

.sc-bcXHqe.dyabIq li > span {
  padding-left: 8px;
}