@media screen and (min-width: 767px) {
  #catalog .products .product .top-icon {
    font-size: 12px;
    font-weight: 600;
  }
}

.bg-red {
  background-color: red !important;
}

select.sorting-types:focus-visible {
  border: none;
  border-radius: 0;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}