.form-el .ui-selectonemenu {
	width: 100% !important;
	background: inherit !important;
	border: inherit !important;
}

.form-el .ui-selectonemenu-label {
	background: none;
	text-indent: 15px;
	color: #3b3b3b;
	opacity: .6;
	font-size: 18px;
	text-indent: 15px;
}

.form-el .ui-selectonemenu-trigger {
	background: none;
	border: none !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 0.5;
}

.past-message-container .count-submit button span {
    color: #ffffff;
}

.form-check-label {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.address-item-list .address-info-content .address-item-container .address-item:last-child {
	border-bottom: 0 !important;
}

.security-title {
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 40px;
}

.user-menu-container .user-menu-content .user-content-item .form-container .change-password-form .form-el input {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
}

@media screen and (max-width: 991px) {
	.user-menu-container .user-menu .user-menu-title img {
		max-width: 50px !important;
		width: 100%;

	}

	.user-menu-container .user-menu .user-menu-title h2 {
		font-size: 18px !important;
		margin-left: 10px !important;
	}
}

/* New Design */
.detail-page-left-menu {
	padding: 24px !important;

	&-head {
		margin-bottom: 30px;;

		img {
			margin-bottom: 14px;
			border-radius: 16px;
		}
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: 0;

			a {
				display: flex;
				flex-direction: row;

				svg {
					margin-right: 16px;
				}

				&:hover {
					color: #0056b3;

					svg {
						fill: #0056b3;

						path {
							fill: #0056b3;
						}
					}
				}
			}
		}
	}
}

.basis-full.user-menu-container {
	background: transparent;
	padding: 0 0 0 2.5rem;

	.user-menu-content {
		.user-content-item {
			ul {
				li {
					a {
						font-size: 16px;

						@media screen and (max-width: 1199px) {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	.form-container {
		background: transparent !important;
	}
}

ul.nav.ml-0 {
	li {
		a {
			text-align: center !important;
		}
	}
}

.tab-content-performance {
	min-height: 405px;
	border-top: 2px solid #eee;
	background: #fff;
	box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
	padding: 20px;
}

.detail-page-left-menu li:hover a {
	color: #00B185 !important;	

	> svg {
		color: #00B185 !important;

		path {
			fill: #00B185 !important;
		}
	}

	.relative {
		> svg {
			color: #00B185 !important;
	
			path {
				fill: #00B185 !important;
			}
		}
	}
}

.detail-page-left-menu li a.active {
	color: #00B185 !important;

	> svg {
		color: #00B185 !important;

		path {
			fill: #00B185 !important;
		}
	}

	.relative {
		> svg {
			color: #00B185 !important;
	
			path {
				fill: #00B185 !important;
			}
		}
	}
}