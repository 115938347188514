#dergi-liste {
    .hashtag-name {
        color: #09027b !important;
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }

    .web-page-desc {
        .intro {
            h2 {
                margin-top: 0;
                margin-bottom: 24px;
            }
        }
    }

}

.dangerously-description {
    p {
        margin-top: 20px;
    }
}