.wheel-page {

  .wheel-spin {
    margin-top: 48px;
    position: relative;

    .sc-gsTCUz.bhdLno {
      margin: 0 auto;
    }

    .oval {
      transform: rotate(45deg) !important;
      width: 360px;
      left: 81px;
      top: 56px;
      display: none;

      @media screen and (min-width: 1536px) {
        left: 122px;
      }

      @media screen and (min-width: 640px) and (max-width: 767px) {
        left: 108px;
      }

      @media screen and (max-width: 639px) {
        width: 100%;
        left: 62px;
        top: 40px;
      }
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;

      @media screen and (min-width: 992px) {
        left: 119px;
      }

      @media screen and (min-width: 1025px) {
        left: 78px;
      }

      @media screen and (min-width: 1280px) {
        left: 20px;
      }

      @media screen and (min-width: 1536px) {
        left: 0;
      }

      > div {
        position: relative;
        transform: rotate(45deg) !important;

        .button-effect {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 136px;
          width: 100%;
          max-height: 136px;
          height: 100%;
          cursor: pointer;

          &:hover {
            > img {
              display: none;
            }
          }
        }

        > button.btn.btn-success {
          width: 136px !important;
          height: 136px !important;
          -webkit-border-radius: 50% !important;
          -moz-border-radius: 50% !important;
          border-radius: 50% !important;
          margin: 0 !important;
          padding: 0 !important;
          box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.53);
          border: solid 2px #fff;
          background-color: #ddd;
          font-size: 24px;
          font-weight: 700;
          line-height: 1.5 !important;
          color: #000;
          text-transform: none;
          // box-shadow: 0 2px 48px 0 #f7a90f, inset 0 0 24px 0 rgba(242, 172, 16, 0.48);
          border: solid 2px #fff;
          // background-image: linear-gradient(to bottom, #0d9488 0%, #037d72);

          &:hover {
            background-color: #eee;
          }
  
          &:disabled {
            opacity: 1 !important;
            background-color: #cccccc;
  
            &:hover {
              background-color: #cccccc;
            }
          }
  
          &:hover {
            background-color: #218838;
          }

          @media screen and (max-width: 767px) {
            width: 100px !important;
            height: 100px !important;
            font-size: 14px;
          }
  
          @media screen and (max-width: 460px) {
            width: 80px !important;
            height: 80px !important;
            font-size: 14px;
          }
  
          @media screen and (max-width: 360px) {
            width: 60px !important;
            height: 60px !important;
            font-size: 13px;
          }
        }
      }
    }
  }

  .game-timer {
    max-width: 128px;
    width: 100%;
    min-height: 260px;
    margin: 0;
    padding: 24px 20px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 24px;
    left: 24px;
    color: #484f55;

    @media screen and (max-width: 900px) {
      text-align: center;
      position: static;
      max-width: 90%;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }

    p {
      font-size: 36px;
      font-weight: 500;
      margin: 0;
    }

    .game-countdown {
      font-size: 32px;
      margin-top: 8px;
      margin-bottom: 24px;

      > div {
        margin: 0 auto;
      }
    }
  }

  .game-size {
    font-size: 36px !important;
  }

  .games-page-question {
    color: #484f55;

    h3 {
      color: #484f55;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 4px 0;
    }

    form {
      .form-group {
        > label {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }

      .form-check {
        label {
          font-size: 16px;
        }
      }
    }
  }
}

// Transforms: Rotates
.wheel-spin {
  transform: rotate(-45deg);
}

.wheel-page .wheel-spin-button > button.btn {
  transform: rotate(45deg);
}

// Triangle
.wheel-spin .gTLZXx {
  transform: rotate(45deg) !important;
  position: absolute;
  z-index: 5;
  width: 17%;
  right: 40px;
  top: 30px;
  display: none;
}

// Kapalı kalmalı !!!
/*
.sc-gsTCUz.bhdLno {
  .sc-bdfBwQ.sc-hKgILt.cIKpxU.gTLZXx {
    position: absolute;
    z-index: 5;
    width: 17%;
    right: 6px;
    top: 0;
    right: 50%;
    transform: rotate(-45deg);
    margin-right: -40px;
    top: -60px;
  }
}*/
.bhdLno {
  min-width: 220px;
  min-height: 220px;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
}

.games-page-introduction {
  .games-page-circle {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    width: 215px;
    height: 215px;
    background-color: #484f55;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .btn.btn-success {
    padding: 16px 48px;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-radius: 8px !important;
    background-color: #54ae4b;
    border-color: #54ae4b;

    &:hover {
      background-color: #28a745;
      border-color: #28a745;
    }
  }

  .wrapper-reward {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    h3 {
      color: #484f55;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 10px 0;
      padding: 0;
    }

    // Girls Point
    h4 {
      color: #fba629;
      font-weight: bold;
      font-size: 48px;
      margin: -40px 0 0 0;
      padding: 0;
      position: absolute;
    }
  }

}

.wheelWin {
  max-width: 0 !important;
  -webkit-transition: max-width 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: max-width 1s ease-in-out;
  
  &.active {
    max-width: 50% !important;

    @media screen and (max-width: 767px) {
      max-width: 100% !important;
      margin-top: 30px;
    }
  }
}

.questionWheelWin {
  height: 0;
  -webkit-transition: height 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: height 1s ease-in-out;

  .wrapper-reward {
    img {
      display: none;
    }
  }

  &.active {
    height: 100% !important;

    .wrapper-reward {
      img {
        display: block;
      }
    }
  }
}

.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
  .control input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #484f55;
  border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #2aa1c0;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: red;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 2px;
  top: 2px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #484f55;
  transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}.control-radio .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
      opacity: 0;
      transform: scale(0);
  }
  20% {
      transform: scale(1);
  }
  100% {
      opacity: 0.01;
      transform: scale(1);
  }
}
@keyframes s-ripple-dup {
 0% {
     transform: scale(0);
  }
 30% {
      transform: scale(1);
  }
  60% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
.control-radio input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}


.mb-3.col-sm-10 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form-check {
    margin-bottom: 10px;
    padding-left: 0;
    width: 50%;
  }
}

.wheel-page [type="radio"]:checked,
.wheel-page [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.wheel-page [type="radio"]:checked + label,
.wheel-page [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    line-height: 32px;
    display: inline-block;
    color: #484f55;
}
.wheel-page [type="radio"]:checked + label:before,
.wheel-page [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border: 2px solid #484f55;
    border-radius: 100%;
    background: transparent;
}
.wheel-page [type="radio"]:checked + label:after,
.wheel-page [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: #484f55;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.wheel-page [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.wheel-page [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.question-wrap-row {
  @media screen and (max-width: 1280px) {
    margin-top: 30px;
  }
}

.games-page.page-container.wheel-page {
  h2 {
    margin-bottom: 20px !important;
  }
}

.wheel-page .wheel-spin .sc-gsTCUz.bhdLno {
  margin: 0 auto;
  border-radius: 50%;

  .sc-dlfnbm {
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.5);
    // background-image: linear-gradient(to bottom, #fbcd1a, #f59e0b);
    background-color: #ddd;
    
    canvas {
      border-radius: 50%;
      padding: 8px;
      background-color: #fff;
      background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0) 104%);
    }
  }
}


.wheel-page [type="radio"]:checked + .wheel-page label:after,
.wheel-page [type="radio"]:not(:checked) + .wheel-page label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: #00b185;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.wheel-page .wheel-spin-button > div > button.btn.btn-success-gray {
  background-image: none !important;
  background-color: #838383 !important;
}

.triangle {
  transform: rotate(45deg) !important;
}

.games-page-introduction .btn.btn-success:hover {
  background-color: #eee !important;
  border-color: #eee !important;
}

@media screen and (max-width: 640px) {
  .relative.select-none {
    margin-left: 50px;
    margin-top: 10px;
  } 

  .img-triangle {
    max-width: 80%;
  }
}

.wrapper-pin-orange {

  @media screen and (min-width: 320px) {
    margin-top: 0;
    margin-left: -20px;
  }

  @media screen and (min-width: 375px) {
    margin-top: 20px;
    margin-left: -24px;
  }

  @media screen and (min-width: 414px) {
    margin-top: 20px;
    margin-left: -26px;
  }

  @media screen and (min-width: 640px) {
    margin-left: -34px;
    margin-top: 20px;
  }

  @media screen and (min-width: 992px) {
    margin-left: -42px;
  }

  @media screen and (min-width: 1024px) {
    margin-left: -42px;
  }

  @media screen and (min-width: 1281px) {
    margin-left: -42px;
  }

  @media screen and (min-width: 1536px) {
    margin-top: 6px;
    margin-left: -42px;
  }
}

.relative.select-none {
  @media screen and (min-width: 320px) and (max-width: 374px) {
    margin-top: 0;
    margin-left: 30px;
  }

  @media screen and (min-width: 375px) and (max-width: 413px) {
    margin-top: 0px;
    margin-left: 10px;
  }

  @media screen and (min-width: 414px) and (max-width: 639px) {
    margin-top: 20px;
    margin-left: 0;
  }
  
  @media screen and (min-width: 640px) and (max-width: 991px) {
    margin-top: 0;
    margin-left: 0;
  }

  @media screen and (min-width: 992px) and (max-width: 1023px) {
    margin-top: 0;
    margin-left: -242px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1276px) {
    margin-top: 0;
    margin-left: -160px;
  }

  @media screen and (min-width: 1277px) and (max-width: 1280px) {
    margin-top: 0;
    margin-left: -42px;
  }

  @media screen and (min-width: 1281px) and (max-width: 1535px) {
    margin-top: 10px;
    margin-left: -36px;
  }
}