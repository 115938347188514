.progressVisualFull {
  display: flex;
  /* change the value if you want different thickness */
  height: 6px;
  margin: 20px 0;
}

.progressVisualPart {
  /* Number of the seconds for complete animation */
  transition: width 2s;
}

.modal-cookie-consent {
  ul.nav {
    li {
      a {
        font-weight: bold;
        margin-right: 20px;
        color: #64748b;

        &.active {
          color: #30353c;
        }

        &:hover {
          color: #30353c;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }
}

.tabs-boxed .tab-active:not(.tab-disabled):not([disabled]) {
  background-color: #00B185;
  color: #fff;

  &:hover {
    background-color: #00B185;
    color: #fff;
  }
}

.tabs-boxed {
  a {
    &:hover {
      color: #00B185;
    }
  }
}

.toggle-accent:checked, .toggle-accent[checked="true"], .toggle-accent[aria-checked="true"] {
  background-color: #00B185;
}