.finance {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 52px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    @media screen and (max-width: 1124px) {
        justify-content: flex-start;
        overflow-x: scroll;
    }

    .finance-content {
        max-width: 100% !important;
        margin-right: 50px;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 1124px) {
            width: 170px;
            min-width: 170px;
            margin-right: 10px;


            &:last-child {
                margin-right: 0;
            }
        }

        span {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
                font-weight: bold;
            }
        }
    }
}

.news-tab {
    background: #f4f4f8;
    padding-top: 25px;
    padding-bottom: 25px;

    .nav-pills {
        padding-bottom: 25px;

        .nav-item {
            min-width: 200px;
            -webkit-border-radius: 16px;
            -moz-border-radius: 16px;
            border-radius: 16px;
            overflow: hidden;
            cursor: pointer;
    
            .nav-link {
                padding: 25px 0;
    
                &.active {
                    background-color: #fff;
                }
    
                div {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
    
                    img {
                        max-width: 56px;
                        margin-bottom: 20px;
                    }
    
                    span {
                        color: #00B185;
                    }
                }
            }
        }
    }

    .wrapper-pagination {
        margin-top: 2.5rem;

        .pagination {
            justify-content: center;

            .page-item {
                .page-link {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .news {
        background-color: #fff;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        overflow: hidden;
        padding: 30px 15px;

        @media screen and (min-height: 768px) {
            min-height: 612px;
        }

        &-list {
            margin: 0;
            list-style: none;

            li {
                list-style: none;
                margin: 0;
                padding: 0;

                a {
                    color: #000;

                    &:hover {
                        opacity: 0.5;
                    }
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }

        &-general {

            > a {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #000;
                margin-bottom: 30px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                > span {
                    margin-top: 10px;
                }

                > h3 {
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0 0 10px 0;
                    font-weight: bold;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    line-height: 21px;
                }

                p.read-more {
                    margin: 10px 0 0 0;
                }
            }
        }

        &-banner {
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 460px;
            background-color: #ccc;
            font-weight: bold;

            @media screen and (min-width: 768px) {
                justify-content: center;
                align-items: flex-start;
            }

            @media screen and (min-width: 240px) and (max-width: 767px) {
                justify-content: center;
                align-items: center;
            }
            
            &-content {
                max-width: 50%;
                width: 100%;
                position: absolute;
                right: 50px;
                bottom: 50px;
                text-align: right;
                -webkit-user-select: none;
                -moz-user-select: none;
                -o-user-select: none;
                user-select: none;

                &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                }

                > h2 {
                    font-size: 30px;
                    line-height: 1.2;
                    margin: 0 0 30px 0;
                    padding: 0;
                    color: #fff;
                    text-shadow: 1px 1px black;
                }

                > span {
                    background: transparent;
                    font-size: 14px;
                    line-height: 1.2;
                    color: #fff;
                    border: 1px #fff solid;
                    display: inline-block;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    border-radius: 30px;
                    padding: 20px 30px;
                }
            }

            .wrapper-empty-news {
                @media screen and (min-width: 768px) {
                    padding-left: 100px;
                }

                img {
                   height: 199px; 
                }
            }
        }
    }

    .wrapper-empty-news {
        background-color: #000;
        min-height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            height: 100px;    
        }
    }
}

.swiper.swiper-news {
    width: 100%;
    height: 100%;

    > .swiper-wrapper {
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
        
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.swiper-container.swiper-news.swiper-container-initialized.swiper-container-horizontal {
    .swiper-wrapper{
        max-height: 330px !important;
        height: 100% !important;

        span.swiper-slide-title {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #000;
            color: #fff;
            width: 100%;
            padding: 10px;
        }
    }
}

.imageable-content {
    > div {
        margin-bottom: 20px;

        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }

        > a {
            text-align: center;
            color: #000;

            &:hover {
                color: rgba(0,0,0, 0.5);
            }

            > img {
                width: 100%;
                height: 210px;
                object-fit: cover;
                border-radius: 20px;
                margin-bottom: 10px;
            }
        }
    }
}

.competition .nav-item {
    &.active {
        -webkit-border-radius: 8px !important;
        -moz-border-radius: 8px !important;
        border-radius: 8px !important;
    }
}