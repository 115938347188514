#dergi-detay.page-magazine-detail {
    .other-articles {
        h2.text-center.mb-sm-8.mb-5 {
            color: #424242 !important;
            font-size: 2rem;
        }
    }

    .rounded-3 {
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
    }


    .article {
        .article-head {
            .article-head-subtitle {
                font-size: 20px;
                color: rgb(9, 2, 123);
                margin-top: 10px;
            }
        }

        .article-body {

            .container.ccc {
                h1 {
                    line-height: 21px;
                }
            }

            p {
                line-height: 1.5 !important;
            }
        }
    }

}

