.main-slider.main-slider-homepage {
  .main-slider-container {
    .swiper-slide {
      > div {
        width: 100%;
        text-align: center;
      }
    }

    .swiper-button-prev, .swiper-button-next {
      background-color: rgba(0,0,0, 0.5);
      width: 50px;
      height: 50px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      transform: none;

      > svg {
        position: absolute;
      }

      &::after {
        display: none;
      }
    }

    .swiper-button-prev {
      > svg {
        left: 12px;
      }
    }

    .swiper-button-next {
      > svg {
        right: 12px;
      }
    }

    @media screen and (max-width: 767px) {
      .swiper-button-prev, .swiper-button-next {
        width: 40px;
        height: 40px;
  
        > svg {
          font-size: 20px !important;
        }
      }

      .swiper-button-prev {
        > svg {
          left: 12px;
        }
      }
  
      .swiper-button-next {
        > svg {
          right: 12px;
        }
      }
    }
  }
}

.main-slider.main-slider-homepage .main-slider-container .swiper-button-prev > svg {
  left: 5px !important;

  @media screen and (max-width: 767px) {
    left: -1px !important;
  }
}

.main-slider.main-slider-homepage .main-slider-container .swiper-button-next > svg {
  right: 5px !important;

  @media screen and (max-width: 767px) {
    right: -1px !important;
  }
}