/* Page Font Family */
@font-face {
    font-family: 'Akrobat';
    src: url('../../../assets/fonts/subset-Akrobat-ExtraBold.woff') format('woff2'),
    url('../../../assets/fonts/subset-Akrobat-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Boxed Book';
    src: url('../../../assets/fonts/subset-BoxedBook-Italic.woff2') format('woff2'),
    url('../../../assets/fonts/subset-BoxedBook-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Camel';
    src: url('../../../assets/fonts/subset-Camel-Bold.woff2') format('woff2'),
    url('../../../assets/fonts/subset-Camel-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Boxed';
    src: url('../../../assets/fonts/subset-BoxedSemibold.woff2') format('woff2'),
    url('../../../assets/fonts/subset-BoxedSemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Camel';
    src: url('../../../assets/fonts/subset-Camel-Bold.woff2') format('woff2'),
    url('../../../assets/fonts/subset-Camel-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Boxed Book';
    src: url('../../../assets/fonts/subset-BoxedBook.woff2') format('woff2'),
    url('../../../assets/fonts/subset-BoxedBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica for JTI Roman';
    src: url('../../../assets/fonts/subset-HelveticaforJTI-Roman.woff2') format('woff2'),
    url('../../../assets/fonts/subset-HelveticaforJTI-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../../assets/fonts/subset-Nexa-Bold.woff2') format('woff2'),
    url('../../../assets/fonts/subset-Nexa-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Boxed';
    src: url('../../../assets/fonts/subset-BoxedMedium.woff2') format('woff2'),
    url('../../../assets/fonts/subset-BoxedMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
/* End Page Font Family */



/* Page Wrapper */
.camel-page {
    /* background: url(../../../assets/images/bg.png); */
}
/* End Page Wrapper */

/* Sliders */
section.main-slider.camel-slider, section.main-slider.camel-secondary-slider {
    overflow: hidden;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: auto !important;
}

section.main-slider.camel-slider .main-slider-container .swiper-slide .main-slider-bg,
section.main-slider.camel-secondary-slider .main-slider-container .swiper-slide .main-slider-bg {
    position: relative !important;
}

section.main-slider.camel-secondary-slider {
    margin-left: 30px;
}

section.main-slider.camel-secondary-slider .main-slider-container .swiper-slide a img {
    margin: 0;
}

section.main-slider.camel-slider .main-slider-bg .row {
    height: 100%;
}

section.main-slider.camel-slider .main-slider-bg .row .col-md-6 {
    height: 100%;
}

section.main-slider.camel-slider .swiper-container, section.main-slider.camel-secondary-slider .swiper-container {
    overflow: hidden;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

@media screen and (min-width: 1800px) {
    section.main-slider.camel-slider, section.main-slider.camel-secondary-slider {
        height: 600px;
    }
}

section.main-slider.camel-secondary-slider .swiper-slide.grayscale .main-slider-bg {
    filter: grayscale(100%);
}

section.main-slider.camel-secondary-slider .swiper-slide h3 {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    padding: 40px;
    width: 100%;
    text-align: center;
    font-family: "Camel";
    font-weight: bold;
    text-shadow: 2px 2px rgba(0, 0, 0, 1.6);
}

section.main-slider.camel-secondary-slider .swiper-slide:nth-child(2n) h3 {
    color: #003783;
}
/* End Sliders */

/* Prev Next Buttons */
.camel-page .swiper-button-prev:after, .swiper-button-prev.swiper-commercial-brochure-popup-prev:after , .camel-page .swiper-button-next:after, .swiper-button-next.swiper-commercial-brochure-popup-next:after {
    background-image: url(../../../assets/images/arrow-right.png) !important;
    width: 48px;
    height: 172px;
}

.camel-page .swiper-button-prev.swiper-commercial-brochure-prev:after,
.camel-page .swiper-button-next.swiper-commercial-brochure-next:after,
.camel-page .swiper-button-prev.swiper-video-prev:after,
.camel-page .swiper-button-next.swiper-video-next:after {
    background-image: url(../../../assets/images/arrow-right-dark.png) !important;
    width: 48px;
    height: 172px;
    background-repeat: no-repeat;
}
/* End Prev Next Buttons */

/* Camel Main Slider */
section.main-slider.camel-slider .main-slider-desktop {
    text-align: left;
    padding: 0 178px;
    color: #fff;
}

section.main-slider.camel-slider .main-slider-desktop h3 {
    font-family: 'Camel';
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;
    color: #003783;
    margin: 40px 0 60px 0;
}

section.main-slider.camel-slider .main-slider-desktop p {
    font-family: 'Nexa';
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #fff;
    margin: 0;
    text-shadow: 2px 2px rgba(0, 0, 0, 1.6);
}

section.main-slider.camel-slider .main-slider-desktop span.camel-page-button {
    font-family: sans-serif;
    margin-top: 40px;
}
/* End Camel Main Slider */

/* Camel Secondary Slider */
section.main-slider.camel-secondary-slider .main-slider-container .swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    border: 1px #fff solid !important;
    margin: 0 4px !important;
}

section.main-slider.camel-secondary-slider .main-slider-container .swiper-container
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #FDAD2B;
    border-color: #FDAD2B !important;
}

section.main-slider.camel-secondary-slider .main-slider-container .swiper-container
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    display: none;
    opacity: 0;
}

section.main-slider.camel-secondary-slider .main-slider-container .swiper-container .swiper-pagination {
    text-align: center;
    top: auto;
    bottom: 25px;
}

section.main-slider.camel-secondary-slider .main-slider-desktop {
    text-align: left;
    padding: 0 60px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;

}

section.main-slider.camel-secondary-slider .main-slider-desktop p {
    position: absolute;
    bottom: 80px;
    left: 40px;
    right: 40px;
    margin-bottom: 0;
    text-shadow: 2px 2px rgba(0, 0, 0, 1.6);
}
/* End Camel Secondary Slider */

/* Camel Page Button */
.camel-page-button {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-color: #003783;
    color: #FFF !important;
    outline: 0;
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    padding: 15px 92px;
    display: inline-block;
}

.camel-page-button:hover {
    background-color: #024BB1;
    color: #fff;
}
/* End Camel Page Button */

.camel-page .container-fluid .row {
    max-width: 100%;
}

/* Leaf Grid */
.wrapper-camel-category {
    display: flex;
    width: 100%;
    /*justify-content: center;*/
    align-items: center;
    padding-left: 116px;
}

.wrapper-camel-category .category-title {
    position: relative;
    text-align: center;
}

.wrapper-camel-category .category-title p {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
}

.wrapper-camel-category .category-title p span {
    max-width: 70%;
}

.wrapper-camel-category-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.wrapper-camel-category-content h3 {
    color: #003783;
    margin: 0;
    padding: 0;
    opacity: 0.8;
    font-size: 36px;
    line-height: 43px;
    max-width: 650px;
}

.wrapper-camel-category-content div.wrapper-button-category-content {
    display: flex;
    align-items: center;
    height: 100%;
}

.wrapper-camel-category-content > div {
    display: flex;
    width: 100%;
    padding: 0 50px;
}

.wrapper-camel-category-content p {
    max-width: 70%;
    padding-right: 10px;
    color: #4A4A4A;
    opacity: 0.8;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 0;
}

.wrapper-camel-category-content a {

}

.camel-page-content-detail {
    z-index: 998;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/bottom-bg.png');
    background-size: cover;
    padding: 100px 45px 100px 130px;
}

.wrapper-info-container {
    background-image: url( '../../../assets/images/info-container-bg.png' );
    background-position: center center;
    background-size: cover;
    padding: 55px 70px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.wrapper-info-container h3 {
    font-size: 36px;
    line-height: 54px;
}

.wrapper-info-container p {
    font-size: 24px;
    line-height: 36px;
}

.wrapper-info-container .info-price p {
    font-size: 30px;
    line-height: 44px;
}

.wrapper-info-container img {
    display: block;
    margin: 0 auto;
}

.camel-page-content-detail h3 {
    color: #003783;
    font-family: 'Camel';
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 36px;
    display: inline-block;
}

.camel-page-content-detail .camel-category-item > h3 {
    text-align: center;
    margin-bottom: 100px;
}

.camel-page-content-detail .camel-category-item hr {
    border-color: #C7BA97;
}

.camel-page-content-detail .camel-category-item p {
    font-family: 'Nexa';
    font-weight: bold;
    color: #003783;
    padding-left: 10px;
    padding-right: 10px;
}

.camel-page-content-detail .camel-category-item .info-price p {
    margin-bottom: 6px;
}

.camel-page-content-detail .camel-category-item .info-price p:last-child {
    margin-bottom: 0;
}

.camel-page-content {
    position: relative;
    background-image: url('../../../assets/images/bottom-bg.png');
    background-size: cover;
    margin-top: 30px;
    padding: 30px 0;

    @media screen and (min-width: 1300px) and (max-width: 1714px) {
        min-height: 1100px;
    }
    
    @media screen and (min-width: 767px) and (max-width: 1299px) {
        min-height: 800px;
    }
}

.wrapper-camel-focus {
    position: relative;
    width: 100%;
}

.wrapper-camel-focus .wrapper-camel-focus-content {
    position: absolute;
    top: 100px;
    left: 60px;
}

.wrapper-camel-focus .wrapper-camel-focus-content h3 {
    color: #003783;
    font-family: 'Camel';
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 60px;
}

.wrapper-camel-focus .wrapper-camel-focus-content p {
    font-family: 'Nexa';
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #fff;
    text-shadow: 2px 2px rgba(0, 0, 0, 1.6);
}

.wrapper-camel-focus .wrapper-camel-focus-button {
    position: absolute;
    bottom: 124px;
    right: 95px;
}

#focus-close-icon {
    top: 56px;
    right: 60px;
    outline: 0;
    z-index: 1000;
}

.btn.btn-camel {
    font-family: 'Nexa';
    font-weight: bold;
    font-size: 36px;
    background-color: #ddd;
    border-color: #fff;
    color: #003783;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding: 25px 72px 19px 72px;
    line-height: 1.2;
    outline: 0;
    box-shadow: none;
}

.styles_modal__gNwvD:not(#disclaimer-modal-area) {
    max-width: 1380px;
    padding: 0;
}

.camel-bottom-row .row.w-100 .col-12.col-md-4:first-child {
    margin-right: 30px;
}

.camel-bottom-row .row.w-100 .col-12.col-md-4:last-child {
    margin-left: -30px;
}

.camel-video-play {
    background: url('../../../assets/images/video-play.png') no-repeat;
    width: 200px;
    height: 200px;
}

.wrapper-commercial-brochure-arrows {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.camel-page .swiper-button-next.swiper-commercial-brochure-next {
    right: 50px;
}

.camel-page .swiper-button-next, .camel-page .swiper-button-prev, .swiper-button-prev.swiper-commercial-brochure-popup-prev, .swiper-button-next.swiper-commercial-brochure-popup-next {
    width: 48px !important;
}

/* Camel Page Brochure Popup Style */
.camel-launch-page .styles_modal__gNwvD {
    background: transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    max-width: 860px;
    /*height: 100%;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
}

.camel-launch-page .styles_modal__gNwvD > div {
    position: relative;
}

.camel-launch-page .styles_modal__gNwvD #focus-close-icon svg path {
    fill: #fff;
}

.camel-launch-page .styles_modal__gNwvD > div #focus-close-icon {
    top: 0;
    right: 0;
    background: url('../../../assets/images/close.png') no-repeat;
    width: 35px;
    height: 69px;
}

.camel-launch-page .styles_modal__gNwvD > div #focus-close-icon > svg {
    display: none;
}

.wrapper-video {
    position: relative;
}

.wrapper-video > a {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
    background: #000;
    z-index: 2;
}

.swiper-commercial-brochure-popup .swiper-button-prev {
    left: 0;
}

.swiper-commercial-brochure-popup .swiper-button-next {
    right: 0;
}

.camel-page-sliders {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}

.camel-page-sliders > section.camel-slider {
    width: 100%;
    max-width: 1400px;
    flex: 0 0 72.916666%
}

.camel-page-sliders > section.camel-slider .main-slider-container .swiper-slide {
    padding: 0;
}

.camel-page-sliders > section.camel-slider .main-slider-container .swiper-slide .main-slider-desktop {
    padding: 0 !important;
}

.camel-page-sliders > section.camel-secondary-slider {
    width: 100%;
    max-width: 484px;
}

.camel-page-sliders > section.camel-secondary-slider .main-slider-container .swiper-slide {
    padding: 0;
}

.camel-page-sliders > section.camel-secondary-slider .main-slider-container .swiper-slide .main-slider-desktop {
    padding: 0 !important;
}

.main-slider.camel-secondary-slider .main-slider-container .swiper-container .swiper-wrapper {
    height: 100%;
}

.main-slider.camel-secondary-slider .main-slider-container .swiper-container .swiper-wrapper .swiper-slide a {
    display: flex;
    width: 100%;
    height: 100%;
}

.main-slider.camel-secondary-slider .main-slider-container .swiper-container .swiper-wrapper .swiper-slide a .main-slider-bg.main-slider-desktop {
    width: 100%;
}

/* Media Queries */

@media screen and (max-width: 1900px) {
    section.main-slider.camel-slider .main-slider-desktop h3 {
        font-size: 36px;
        line-height: 42px;
        margin: 40px 0 30px 0;

    }
    section.main-slider.camel-slider .main-slider-desktop p {
        font-size: 24px;
        line-height: 30px;
    }

    .camel-page-button {
        font-size: 24px;
        line-height: 30px;
        padding: 6px 36px;
        display: inline-block;
    }
}

@media screen and (max-width: 1680px) {
    .wrapper-camel-category .category-title p {
        font-size: 30px;
    }

    .wrapper-camel-category-content h3 {
        font-size: 28px;
    }
}

@media screen and (max-width: 1440px) {

    .category-title {
        max-width: 345px !important;
    }

    .wrapper-camel-category, .wrapper-camel-category.video-category {
        padding-left: 10px;
    }

    /* Popup */
    .wrapper-camel-focus .wrapper-camel-focus-content h3 {
        font-size: 36px;
        line-height: 42px;
    }

    .wrapper-camel-focus .wrapper-camel-focus-content p {
        font-size: 24px;
        line-height: 30px;
    }

    .wrapper-camel-focus-button .btn.btn-camel {
        font-size: 20px;
        padding: 10px 40px 5px 40px;
    }

    /* Camel Slider */
    section.main-slider.camel-slider .main-slider-desktop h3 {
        font-size: 36px;
        line-height: 42px;
        margin: 40px 0 30px 0;
    }

    section.main-slider.camel-slider .main-slider-desktop p {
        font-size: 24px;
        line-height: 30px;
    }

    section.main-slider.camel-slider .main-slider-desktop {
        padding: 0 100px;
    }

    .camel-page-button {
        font-size: 20px;
        line-height: 30px;
        padding: 6px 36px;
        display: inline-block;
    }

    .category-img {
        max-width: 100px;
    }

    .camel-page .swiper-button-prev.swiper-commercial-brochure-prev:after,
    .camel-page .swiper-button-next.swiper-commercial-brochure-next:after,
    .camel-page .swiper-button-prev.swiper-video-prev:after,
    .camel-page .swiper-button-next.swiper-video-next:after,
    .camel-page .swiper-button-prev.swiper-video-detail-prev:after,
    .camel-page .swiper-button-next.swiper-video-detail-next:after {
        background-size: 20px;
        width: 20px;
        height: 72px;
    }

    .camel-videos-buttons .swiper-video-prev, .camel-videos-buttons .swiper-video-next {
        margin: 0 !important;
    }

    .camel-videos-buttons {
        justify-content: flex-start !important;
    }

    .wrapper-camel-category .category-title p {
        font-size: 20px;
    }

    .wrapper-camel-category-content h3 {
        font-size: 24px;
        line-height: 30px;
    }

    .wrapper-camel-category-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .close-button {
        right: 0 !important;
    }

    .close-button .close-dark {
        background-size: 20px;
        width: 20px;
        height: 39px;
    }

    .wrapper-info-container h3 {
        font-size: 24px;
        line-height: 36px;
    }

    .wrapper-info-container p {
        font-size: 20px;
        line-height: 28px;
    }

    .wrapper-info-container .info-price p {
        font-size: 24px;
        line-height: 30px;
    }
}

@media screen and (max-width: 1440px) {

    .wrapper-camel-category .category-title {
        margin: 0 auto;
    }

    /* Camel Main Slider */
    section.main-slider.camel-slider .main-slider-desktop h3 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 20px;
    }

    section.main-slider.camel-slider .main-slider-desktop p {
        font-size: 14px;
        line-height: 21px;
    }

    section.main-slider.camel-slider .main-slider-desktop span.camel-page-button {
        margin-top: 20px;
        font-size: 14px;
        line-height: 21px;
    }

    /* Camel Secondary Slider */
    section.main-slider.camel-secondary-slider .main-slider-desktop {
        padding: 20px 40px;
        text-align: left;
    }

    section.main-slider .main-slider-container .swiper-slide .main-slider-bg {
        padding: 20px;
    }

    section.main-slider.camel-secondary-slider .main-slider-desktop p {
        bottom: 60px;
        font-size: 12px;
    }

    .wrapper-camel-category-content h3 {
        font-size: 16px;
        line-height: 24px;
    }

    .camel-page-button {
        font-size: 16px;
        line-height: 24px;
        padding: 4px 24px;
    }

    .camel-page .swiper-button-prev.swiper-commercial-brochure-prev {
        left: 20px;
    }

    .camel-page .swiper-button-next.swiper-commercial-brochure-next {
        right: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .wrapper-camel-category .category-title p {
        font-size: 16px;
    }
}

@media screen and (max-width: 991px) {
    .camel-page-sliders {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .camel-page-sliders > section.camel-slider {
        width: 100%;
        max-width: 1400px;
        flex: 0 0 100%;
        margin: 0 !important;
        padding: 0 30px 30px 30px;
    }

    .main-slider.camel-secondary-slider {
        margin-top: 20px !important;
    }

    .camel-page .swiper-button-prev.swiper-commercial-brochure-prev {
        left: 10px;
    }

    .camel-page .swiper-button-next.swiper-commercial-brochure-next {
        right: 10px;
    }

    section.main-slider.camel-slider {
        margin-top: 0;
    }

    section.main-slider.camel-slider, section.main-slider.camel-secondary-slider {
        margin-left: 30px;
        margin-right: 30px;
    }

    section.main-slider.camel-secondary-slider {
        height: 484px !important;
    }
}

@media screen and (max-width: 767px) {
    section.main-slider.camel-slider {
        margin-top: 20px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 0 !important;
    }

    section.main-slider.camel-slider, section.main-slider.camel-secondary-slider {
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 0 !important;
    }

    section.main-slider.camel-slider .main-slider-bg.main-slider-mobil {
        border-radius: 0;
        padding: 0 !important;
    }

    section.main-slider.camel-slider .swiper-container {
        border-radius: 0 !important;
    }

    .wrapper-camel-category .category-title {
        margin: 0 auto;
    }

    .wrapper-camel-category {
        padding: 0 !important;
        flex-direction: column;
    }

    .camel-page .container-fluid .row {
        margin: 0 auto;
    }

    .camel-bottom-row .row.w-100 .col-12.col-md-4:first-child, .camel-bottom-row .row.w-100 .col-12.col-md-4:last-child {
        margin: 0 !important;
    }

    .wrapper-camel-category-content h3 {
        text-align: center;
    }

    .main-slider.camel-slider {
        margin-top: 0 !important;
    }

    .main-slider.camel-secondary-slider {
        margin-top: 30px !important;
    }

    .category-img {
        max-width: 100px;
    }

    #focus-close-icon {
        top: 10px;
        right: 10px;
    }

    section.main-slider.camel-secondary-slider {
        max-width: 360px;
        margin: 0 auto;
    }

    .wrapper-camel-category-items {
        margin: 30px 0 !important;
    }

    .camel-page-content-detail {
        padding: 30px;
        overflow-y: auto;
    }

    .camel-page-content-detail .col-12.col-md-2 img, .wrapper-commercial-brochure-arrows {
        display: none;
    }

    .main-slider.camel-secondary-slider h3 {
        color: #fff;
        text-align: center;
        font-size: 24px !important;
        margin-top: 0;
        padding: 0 20px;
        text-shadow: 2px 2px rgba(0, 0, 0, 1.6);
        font-family: 'Camel';
        font-weight: bold;
    }

    .main-slider.camel-secondary-slider p {
        color: #fff;
        text-align: center;
        font-size: 14px;
        padding: 0 20px;
        opacity: 0.8;
        position: absolute;
        bottom: 40px;
        left: 0;
        font-family: 'Nexa';
        font-weight: bold;
        text-shadow: 2px 2px rgba(0, 0, 0, 1.6);
        margin-bottom: 10px;
    }

    .wrapper-info-container {
        padding: 15px 20px;
    }

    .wrapper-info-container h3 {
        display: none;
    }

    .wrapper-info-container p {
        font-size: 14px;
        line-height: 20px;
    }

    .wrapper-info-container .info-price p {
        font-size: 16px;
        line-height: 22px;
    }

    .wrapper-camel-category-content {
        flex-direction: column;
        margin-top: 30px;
    }

    .wrapper-camel-category-content .camel-page-button {
        margin-top: 30px;
    }

    section.main-slider.camel-secondary-slider .swiper-slide h3 {
        padding: 20px;
    }

    /* Mobile Brochure */
    .swiper-container.swiper-commercial-brochure .swiper-wrapper {
        flex-direction: column;
    }

    .swiper-container.swiper-commercial-brochure .swiper-wrapper .swiper-slide {
        margin-bottom: 30px;
        width: 100% !important;
        margin-right: 0 !important;
    }

    .camel-page-content-detail h3 {
        font-size: 20px;
    }

    .swiper-container.swiper-commercial-brochure .swiper-wrapper {
        transform: none !important;
    }

    .swiper-container.swiper-video-slider .swiper-wrapper {
        flex-direction: column;
    }

    .swiper-container.swiper-video-slider .swiper-wrapper .swiper-slide {
        width: 100%;
    }

    .camel-launch-page .styles_modal__gNwvD > div #focus-close-icon {
        background-size: 20px;
        width: 20px;
        height: 39px;
        top: 10px;
        right: 10px;
    }

    .swiper-button-prev.swiper-commercial-brochure-popup-prev, .swiper-button-next.swiper-commercial-brochure-popup-next {
        display: none;
    }
    .wrapper-camel-category-content div.wrapper-button-category-content {
        justify-content: center;
    }

    .camel-page-sliders > section.camel-secondary-slider .main-slider-container .swiper-slide .main-slider-desktop {
        display: none !important;
    }

    .camel-page-sliders > section.camel-slider .main-slider-container .swiper-slide .main-slider-desktop {
        display: none !important;
    }

    section.main-slider.camel-slider .main-slider-bg.main-slider-mobil {
        overflow: hidden;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    section.main-slider .main-slider-container .swiper-slide a h3 > img {
        max-width: 80%;
        margin-top: 30px !important;
    }

    section.main-slider.camel-secondary-slider p {
        margin-bottom: 30px;
    }

    section.main-slider.camel-secondary-slider .main-slider-container .swiper-container .swiper-wrapper .swiper-slide a > .main-slider-bg.main-slider-mobile, section.main-slider .main-slider-container .swiper-slide .main-slider-bg {
        padding: 0 !important;
    }

    section.main-slider.camel-secondary-slider {
        height: 360px !important;
    }

}

@media screen and (max-width: 575px) {
    section.main-slider.camel-secondary-slider {
        max-width: 300px;
        margin: 0 auto;
    }

    section.main-slider.camel-secondary-slider {
        height: 300px !important;
    }
}

/* End Media Queries */

.category-title {
    max-width: 463px;
}

/*.category-title img {*/
/*    width: 463px;*/
/*    height: 393px;*/
/*}*/

/*.video-slider {*/
/*    width: 100% !important;*/
/*}*/

.swiper-video-container {
    width: 100%;
    height: 100%;
    background: transparent;
}

.swiper-container.swiper-video-slider {
    width: 100%;
    height: 100%;
}

.swiper-container.swiper-video-slider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
}

/*.swiper-container.swiper-video-slider .swiper-slide img {*/
/*    display: block;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*}*/

.swiper-container.swiper-video-slider .swiper-slide {
    width: 33%;
}

.wrapper-camel-category-items {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.camel-videos-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.camel-videos-buttons .swiper-video-prev, .camel-videos-buttons .swiper-video-next {
    position: static !important;
    top: auto !important;
    left: auto !important;
    margin: 20px 0 0 0;
    padding: 0;
    width: 48px;
}

.close-button {
    position: absolute;
    top: 0;
    right: 100px;
    z-index: 999;
}
.close-dark {
    background-image: url("../../../assets/images/close-dark.png");
    background-repeat: no-repeat;
    width: 35px;
    height: 69px;
    display: block;
}

.video-category {
    padding: 0 0 0 116px;
}

.camelyellowDetailPage {
    max-width: 341px;
}

.header-detail-page {
    padding-bottom: 20px;
}

.wrapper-camel-price-list > a {
    display: inline-block;
    text-align: center;

    > img{
        max-width: 562px;
        width: 100%;

        @media screen and (max-width: 1299px) {
            max-width: 340px;
        }
    }
}

.img-fluid.camel-price-item {
    max-width: 762px !important;
    width: 100%;
}


.react-responsive-modal-modal {
    max-width: 700px !important;
    width: 100% !important;
}