.wrapper-top-announcement {
    position: relative;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
}

.wrapper-top-announcement .top-announcement {
    padding: 0;
}

.wrapper-top-announcement .top-announcement .top-announcement-content .row {
    max-width: 100%;
}

.wrapper-top-announcement .top-announcement .top-announcement-content h1 {
    color: #FFEB37;
    font-weight: bold;
    font-size: 60px;
}

.wrapper-top-announcement .top-announcement .top-announcement-content p {
    margin-top: 60px;
    color: #fff;
    font-size: 40px;
}

.wrapper-top-announcement .top-announcement .top-announcement-content a.continue-btn {
    color: #032B8B;
    background-color: #FFEB37;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding: 25px 70px;
    font-weight: bold;
    display: inline-flex;
    text-align: center;
}

.wrapper-top-announcement .top-announcement .top-announcement-content .top-announcement-close {
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
}
@media only screen and (max-width: 990px) {
    .header-menu {
        position: sticky !important;
    }
}

/* Media Queries */

@media screen and (max-width: 1674px) {
    .wrapper-top-announcement .top-announcement {
        padding: 0;
    }

    .wrapper-top-announcement .top-announcement .top-announcement-content h1 {
        font-size: 36px;
    }

    .wrapper-top-announcement .top-announcement .top-announcement-content p {
        font-size: 24px;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .wrapper-top-announcement .top-announcement .top-announcement-content a.continue-btn {
        padding: 15px 30px;
        font-size: 14px;
    }
}

@media screen and ( max-width: 767px ) {
    .wrapper-top-announcement .top-announcement {
        padding: 0;
    }

    .wrapper-top-announcement .top-announcement .top-announcement-content p {
        font-size: 14px;
        margin: 20px 0;
    }
}
